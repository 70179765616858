import "core-js/modules/es.function.name.js";
import numberInput from '@/mixin/number-input';
export default {
  props: {
    detailsData: {
      type: Object,
      default: null
    },
    isExternal: {
      type: Boolean,
      default: false
    },
    // 品种详情信息
    pageDataSource: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      isBg: false,
      messages: "",
      value: null,
      isMin: false,
      isMax: false,
      isDetailsData: true
    };
  },
  mixins: [numberInput],
  computed: {
    // 最大限制购买数
    isLimit: function isLimit() {
      if (this.detailsData.oldqty != null || this.detailsData.oldqty != 0) {
        if (this.detailsData.availableNum <= this.detailsData.oldqty) {
          return false;
        }
      }
      if (this.detailsData.limitSaleNumber === 0) {
        return true;
      } else {
        if (this.detailsData.totalNumber >= this.detailsData.limitSaleNumber) {
          return false;
        } else {
          return true;
        }
      }
    },
    // 最少购买数
    notLowerSale: function notLowerSale() {
      if (this.detailsData.totalNumber <= this.detailsData.notLowerSale) {
        return false;
      } else {
        return true;
      }
    },
    num: function num() {
      if (this.detailsData.totalNumber === 1 || this.detailsData.totalNumber === "null" || this.detailsData.totalNumber === 0) {
        return true;
      } else {
        return false;
      }
    },
    isInput: function isInput() {
      if (this.detailsData.totalNumber <= this.detailsData.notLowerSale) {
        return true;
        if (this.detailsData.totalNumber > 1) {
          return false;
        } else {
          return true;
        }
      }
    },
    isCarList: function isCarList() {
      return this.$route.name === "cartList";
    },
    isDetails: function isDetails() {
      return this.$route.name === "details";
    }
  },
  mounted: function mounted() {
    if (this.isDetails) {
      this.detailsData.totalNumber = this.$route.params.sale ? this.$route.params.sale : this.detailsData.totalNumber;
    }
  },
  watch: {
    isBg: {
      handler: function handler() {
        this.detailsData.totalNumber > 1 ? this.isBg = true : this.isBg = false;
      },
      deep: true
    },
    value: {
      handler: function handler() {
        this.value === "" ? this.isBg = true : this.isBg = false;
        this.$emit("isDetailsData", this.value);
      },
      deep: true
    }
  },
  methods: {
    handleInput: function handleInput(value) {
      this.value = value;
      if ((value + "").length >= 5) {
        return this.detailsData.totalNumber = 9999;
      }
      if (value == 0) {
        this.value = 1;
        this.detailsData.totalNumber = 1;
      } else {
        this.isBg = false;
      }
      if (this.detailsData.limitSaleNumber != 0 || this.detailsData.notLowerSale != 0) {
        if (this.detailsData.limitSaleNumber == 0 && this.detailsData.notLowerSale != 0) {
          if (value < this.detailsData.notLowerSale) {
            this.isMin = true;
          } else {
            this.isMin = false;
          }
        }
        if (this.detailsData.limitSaleNumber != 0 && this.detailsData.notLowerSale == 0) {
          if (value > this.detailsData.limitSaleNumber) {
            this.isMax = true;
          } else {
            this.isMax = false;
          }
        }
      }
      // 当购物车数量太大时 不要请求接口
      if (this.detailsData.totalNumber > 9999 || this.detailsData.totalNumber === 0) {
        return;
      }
      if (this.isCarList) {
        this.$parent.input(this.detailsData);
      }
    },
    handleBlur: function handleBlur() {
      if (this.value < this.detailsData.kcNumber) {
        this.messages = "";
      }
    },
    // --
    handleMinus: function handleMinus() {
      if (this.detailsData.totalNumber - 1 === this.detailsData.limitSaleNumber) {
        this.isMax = false;
      }
      if (this.num) {
        return;
      }
      if (this.detailsData.totalNumber === null) {
        return;
      }
      this.detailsData.totalNumber--;
      if (this.verifySpecialOfferChooseNum()) return;
      if (this.addCardVerify()) return;
      this.$emit("handleMinus", this.handleMinus);
    },
    // ++
    handleSign: function handleSign() {
      if (this.detailsData.totalNumber + 1 === this.detailsData.notLowerSale) {
        this.isMin = false;
      }
      if (this.addCardVerify()) return;
      this.detailsData.totalNumber++;
      this.$emit("handleSign", this.handleSign);
    }
  }
};