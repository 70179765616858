export var infoLabelList = [{
  label: "商品名称",
  props: "productName"
}, {
  label: "商品规格",
  props: "specifications"
}, {
  label: "生产厂家",
  props: "produceFactory"
}, {
  label: "通用名",
  props: "genericName"
},
// {
//   label: "委托加工",
//   props: "machine"
// },
{
  label: "批准文号",
  props: "approvalNumber"
},
// {
//   label: "商品批号",
//   props: "jobber"
// },
{
  label: "商品包装",
  props: "sellUnit"
},
// {
//   label: "执行标准",
//   props: "implementStandard"
// },
{
  label: "产品剂型",
  props: "formulation"
}, {
  label: "处方分类",
  props: "ption"
},
// {
//   label: "产品专利",
//   props: "productPatent"
// },
// {
//   label: "新药",
//   props: "ifNewProduct"
// },
{
  label: "医保类型",
  props: "health"
}, {
  label: "商品条码",
  props: "barcode"
}, {
  label: "商品分类",
  props: "catalogName"
}, {
  label: "贮存条件",
  props: "storageConditions"
}, {
  label: "中包装管控",
  props: "control"
}, {
  label: "商品编码",
  props: "productCode"
},
// {
//   label: "经营范围",
//   props: "business"
// },
// {
//   label: "产品编号",
//   props: "productCode"
// },
// {
//   label: "产品品牌",
//   props: "brand"
// },
{
  label: "效期",
  props: "nearEffectivePeriod"
}
// {
//   label: "远效期",
//   props: "farEffectivePeriod"
// }
];