import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.string.trim.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    class: [{
      'number-input-bg': _vm.detailsData.status == '4'
    }, 'number-input']
  }, [_vm._t("top"), _c('div', {
    staticClass: "input-box"
  }, [_vm.detailsData.status != '4' && _vm.notLowerSale ? _c('span', {
    class: _vm.detailsData.totalNumber === 1 || _vm.isBg || _vm.isInput ? 'bg-minus minus' : 'minus',
    on: {
      "click": function click($event) {
        return _vm.handleMinus();
      }
    }
  }, [_vm._v("-")]) : _c('span', {
    staticClass: "bg-minus minus"
  }, [_vm._v("-")]), _c('div', {
    staticClass: "field"
  }, [_c('div', {
    staticClass: "control"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim.number",
      value: _vm.detailsData.totalNumber,
      expression: "detailsData.totalNumber",
      modifiers: {
        "trim": true,
        "number": true
      }
    }],
    staticClass: "input",
    attrs: {
      "type": "number",
      "placeholder": "0  ",
      "maxlength": "4",
      "disabled": _vm.detailsData.status == '4'
    },
    domProps: {
      "value": _vm.detailsData.totalNumber
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.detailsData, "totalNumber", _vm._n($event.target.value.trim()));
      }, function ($event) {
        _vm.handleInput(Number(_vm.detailsData.totalNumber));
      }],
      "blur": [_vm.handleBlur, function ($event) {
        return _vm.$forceUpdate();
      }]
    }
  })])]), _vm.detailsData.status != '4' && _vm.isLimit ? _c('span', {
    staticClass: "sign",
    on: {
      "click": _vm.handleSign
    }
  }, [_vm._v("+")]) : _c('span', {
    staticClass: "bg-minus sign"
  }, [_vm._v("+")])]), _vm._t("dome"), _c('div', {
    class: [{
      'messages-top': _vm.isDetails
    }, 'messages']
  }, [_vm.isMin ? _c('p', {
    staticClass: "help is-danger"
  }, [_vm._v(" 您输入的购买数量小于最低数量要求！ ")]) : _vm._e(), _vm.isMax ? _c('p', {
    staticClass: "help is-danger"
  }, [_vm._v(" 您输入的购买数量大于最多数量限制！ ")]) : _vm._e()])], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };