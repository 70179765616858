export default {
  data: function data() {
    return {};
  },
  methods: {
    // 特价品种加入购物车前验证
    verifySpecialOfferChooseNum: function verifySpecialOfferChooseNum() {
      var _this$pageDataSource = this.pageDataSource,
        isMediumPackage = _this$pageDataSource.isMediumPackage,
        isActivityGood = _this$pageDataSource.isActivityGood,
        activityGoodInfo = _this$pageDataSource.activityGoodInfo,
        mediumPackage = _this$pageDataSource.mediumPackage;

      // 中包装实际购买数量
      var count = this.detailsData.totalNumber * mediumPackage;

      // 中包装品种
      if (isMediumPackage == 1) {
        // 特价品种，中包装实际购买数量不能小于起购数量
        if (isActivityGood && count < activityGoodInfo.activityCount) {
          this.detailsData.totalNumber = activityGoodInfo.activityCount / mediumPackage;
          this.$messageTip("\u8BE5\u5546\u54C1\u4E3A\u7279\u4EF7\u5546\u54C1\uFF0C\u8D2D\u4E70\u7684\u6570\u91CF\u5FC5\u987B\u6EE1\u8DB3\u6D3B\u52A8\u6570\u91CF ".concat(activityGoodInfo.activityCount), 'warning');
          return true;
        }
      } else {
        // 特价品种，选购数量不能小于起购数量
        if (isActivityGood && this.detailsData.totalNumber < activityGoodInfo.activityCount) {
          this.$messageTip("\u8BE5\u5546\u54C1\u4E3A\u7279\u4EF7\u5546\u54C1\uFF0C\u8D2D\u4E70\u7684\u6570\u91CF\u5FC5\u987B\u6EE1\u8DB3\u6D3B\u52A8\u6570\u91CF ".concat(activityGoodInfo.activityCount), 'warning');
          this.detailsData.totalNumber = activityGoodInfo.activityCount;
          return true;
        }
      }
      return false;
    },
    // 品种库存不足时，加入购物车验证
    addCardVerify: function addCardVerify() {
      var _this$pageDataSource2 = this.pageDataSource,
        isMediumPackage = _this$pageDataSource2.isMediumPackage,
        mediumPackage = _this$pageDataSource2.mediumPackage,
        availableNum = _this$pageDataSource2.availableNum;
      // 中包装实际购买数量
      var count = this.detailsData.totalNumber * mediumPackage;

      // 中包装品种
      if (isMediumPackage == 1) {
        // 中包装实际购买数量 > 品种库存数量
        if (count > availableNum) {
          this.$messageTip("\u5E93\u5B58\u4E0D\u8DB3\uFF0C\u53EF\u8D2D\u4E70\u6700\u5927\u6570\u91CF\u4E3A".concat(availableNum), 'warning');
          return true;
        }
      } else {
        // 非中包装品种 -- 选购数量 > 品种库存数量
        if (this.detailsData.totalNumber > availableNum) {
          this.$messageTip("\u5E93\u5B58\u4E0D\u8DB3\uFF0C\u53EF\u8D2D\u4E70\u6700\u5927\u6570\u91CF\u4E3A".concat(availableNum), 'warning');
          return true;
        }
      }
      return false;
    }
  }
};