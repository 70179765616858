var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    class: ['activity_bg', 'red', _vm.isDetail ? '' : 'activity_bg_width'],
    style: [_vm.bgStyle]
  }, [_c('div', {
    staticClass: "activity_right"
  }, [_vm.title ? _c('span', {
    staticClass: "red_packet_text",
    style: {
      'background': _vm.type === 1 ? _vm.type_1 : _vm.type === 2 ? _vm.type_2 : _vm.type_3,
      borderColor: _vm.borderColor,
      'color': _vm.titleColor
    }
  }, [_vm._v(_vm._s(_vm.activeName))]) : _vm._e(), _vm.type === 3 ? _c('span', {
    staticClass: "activity_text"
  }, [_vm._v(_vm._s(_vm.activeContent))]) : _vm._e(), _vm.type !== 3 ? _c('span', [_c('span', {
    staticClass: "activity_text"
  }, [_vm._v(_vm._s(_vm._f("replaceStr")(_vm.activeContent)))]), _c('span', {
    style: {
      'color': _vm.type === 1 ? '#E5432E' : _vm.type === 2 || _vm.type == 4 ? '#E5432E' : ''
    }
  }, [_vm._v(_vm._s(_vm.type === 1 ? "减" : _vm.type === 2 || _vm.type === 4 ? "返" : ""))]), _c('span', {
    staticClass: "activity_text"
  }, [_vm._v(_vm._s(_vm._f("replaceStr1")(_vm.activeContent)))])]) : _vm._e()]), _vm._t("default")], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };